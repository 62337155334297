import React from "react";
import "./App.scss";
import { Router, Switch, Route, Redirect } from "react-router-dom";

import history from "./components/common/history";
import { useTranslation } from "react-i18next";
import recaptcha from "./components/common/ReCaptcha";
import {
  Header,
  Footer,
  LandingPage,
  RequestBirthRecord,
  VerifyIdentityInState,
  VerifyIdentityOutOfState,
  RequestVitalRecord,
  RequestDeathRecord,
  MarriageVerificationPage,
  BirthCertificateInfo,
  DeathCertificateInfo,
  UnableToVerify,
  ShippingInfo,
  OrderReceipt,
  OrderFound,
  OrderStatusPage,
  Payment,
  DeathVerificationPage,
  BirthVerificationPage,
  DivorceVerificationPage,
  Error,
  OrderReviewFlow,
  ErrorPage,
  ErrorRefPage,
  RequestFetalDeath,
  RequestStillbornDeath
} from "./components/common/";
import FetalDeathInfo from "./components/common/forms/fetalDeathInfo/FetalDeathInfo";

function App() {
  const { t } = useTranslation();
  recaptcha();
  return (
    <React.Fragment>
      <Router history={history}>
        <div className="main-container">
          <Header />
          <Switch>
            <Route exact path="/ovra/order-vital-records">
              {" "}
              <LandingPage />
            </Route>
            <Route exact path="/ovra/es/solicitar-registros-vitales">
              {" "}
              <LandingPage />
            </Route>
			<Route exact path="/">
              {" "}
              <Redirect to="/ovra/order-vital-records"/>
            </Route>
            <Route exact path="/ovra/">
              {" "}
              <Redirect to="/ovra/order-vital-records"/>
            </Route>
            <Route exact path="/ovra/es/">
              {" "}
              <Redirect to="/ovra/es/solicitar-registros-vitales"/>
            </Route>
            <Route exact path="/ovra/vital-record-order-status/">
              {" "}
              <OrderStatusPage />
            </Route>
            <Route
              exact
              path="/ovra/es/estado-del-pedido-de-registros-vitales/"
            >
              {" "}
              <OrderStatusPage />
            </Route>

            <Route exact path="/ovra/vital-record-order-found/">
              {" "}
              <OrderFound />
            </Route>
            <Route path="/ovra/select-vital-record-order">
              {" "}
              <RequestVitalRecord />
            </Route>
            <Route path="/ovra/es/seleccionar-un-registro-vital-para-solicitar">
              {" "}
              <RequestVitalRecord />
            </Route>

            <Route path="/ovra/order-fetal-death-certificate">
              {" "}
              <RequestFetalDeath />
            </Route>

            <Route path="/ovra/order-stillbirth-death-certificate">
              {" "}
              <RequestStillbornDeath />
            </Route>

            <Route path="/ovra/solicitar-fetal-death-certificate">
              {" "}
              <RequestFetalDeath />
            </Route>

            <Route path="/ovra/solicitar-stillbirth-death-certificate">
              {" "}
              <RequestStillbornDeath />
            </Route>

            <Route path="/ovra/order-birth-certificate">
              {" "}
              <RequestBirthRecord />
            </Route>
            <Route path="/ovra/es/solicitar-certificado-de-nacimiento">
              {" "}
              <RequestBirthRecord />
            </Route>

            <Route path="/ovra/order-birth-verification-letter">
              {" "}
              <BirthVerificationPage />
            </Route>
            <Route path="/ovra/es/solicitar-carta-de-verificacion-de-nacimiento">
              {" "}
              <BirthVerificationPage />
            </Route>
            <Route path="/ovra/order-death-certificate">
              {" "}
              <RequestDeathRecord />
            </Route>
            <Route path="/ovra/es/solicitar-certificado-de-defuncion">
              {" "}
              <RequestDeathRecord />
            </Route>
            <Route path="/ovra/order-death-verification-letter">
              {" "}
              <DeathVerificationPage />
            </Route>
            <Route path="/ovra/es/solicitar-carta-de-verificacion-de-defuncion">
              {" "}
              <DeathVerificationPage />
            </Route>
            <Route path="/ovra/order-marriage-verification-letter">
              {" "}
              <MarriageVerificationPage />
            </Route>
            <Route path="/ovra/es/solicitar-carta-de-verificacion-de-matrimonio">
              {" "}
              <MarriageVerificationPage />
            </Route>
            <Route path="/ovra/order-divorce-verification-letter">
              {" "}
              <DivorceVerificationPage />
            </Route>
            <Route path="/ovra/es/solicitar-carta-de-verificacion-de-divorcio">
              {" "}
              <DivorceVerificationPage />
            </Route>
            <Route path="/ovra/identity-verification">
              {" "}
              <VerifyIdentityInState />
            </Route>
            <Route path="/ovra/identity-verification">
              {" "}
              <VerifyIdentityOutOfState />
            </Route>
            <Route path="/ovra/verification-failed">
              {" "}
              <UnableToVerify />
            </Route>
            <Route path="/ovra/es/fallo-en-la-verificacion">
              {" "}
              <UnableToVerify />
            </Route>
            <Route path="/ovra/birth-certificate-information">
              {" "}
              <BirthCertificateInfo />
            </Route>
            <Route path="/ovra/death-certificate-information">
              {" "}
              <DeathCertificateInfo />
            </Route>
            <Route path="/ovra/fetal-death-certificate-information">
              {" "}
              <FetalDeathInfo />
            </Route>
            <Route path="/ovra/order-review">
              {" "}
              <OrderReviewFlow value={0} />
            </Route>
            <Route path="/ovra/shipping-info">
              {" "}
              <OrderReviewFlow value={1} />
            </Route>
            <Route path="/ovra/enter-your-email">
              {" "}
              <OrderReviewFlow value={1} />
            </Route>
            <Route path="/ovra/payment-info">
              {" "}
              <OrderReviewFlow value={2} />
            </Route>
            <Route path="/ovra/order-receipt">
              {" "}
              <OrderReviewFlow value={3} />
            </Route>
            <Route path="/ovra/es/revision-del-pedido">
              {" "}
              <OrderReviewFlow value={0} />
            </Route>
            <Route path="/ovra/es/informacion-de-envio">
              {" "}
              <OrderReviewFlow value={1} />
            </Route>
            <Route path="/ovra/es/ingrese-su-correo-electrónico">
              {" "}
              <OrderReviewFlow value={1} />
            </Route>
            <Route path="/ovra/es/informacion-de-pago">
              {" "}
              <OrderReviewFlow value={2} />
            </Route>
            <Route path="/ovra/es/recibo-de-pedido">
              {" "}
              <OrderReviewFlow value={3} />
            </Route>
            <Route path="/ovra/shipping-info">
              {" "}
              <ShippingInfo />
            </Route>
            <Route path="/ovra/error">
              {" "}
              <ErrorRefPage />
            </Route>
            <Route path="/ovra/payment-form">
              {" "}
              <Payment />
            </Route>
            <Route path="/ovra/order-receipt">
              {" "}
              <OrderReceipt />
            </Route>
            <Route path="/ovra/404">
              <ErrorPage />
            </Route>
            <Redirect to="/ovra/404">
              <ErrorPage />
            </Redirect>
          </Switch>

          <div className="footer-padding">&nbsp;</div>
          <Footer />
        </div>
      </Router>
    </React.Fragment>
  );
}

export default App;
