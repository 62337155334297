import axios from "axios";
import PostCall from "./CommonApi";

export const BirthVerificationLetter = (props) => {
    let url = "/v1/ovra/rest/services/tgov/ovra/verificationletter/v1/generatebirthverificationletter";
    let requestObject = props;
    return PostCall(url,requestObject);
};


export const DeathVerificationLetter = (props) => {
    let url = "/v1/ovra/rest/services/tgov/ovra/verificationletter/v1/generatedeathverificationletter";
    let requestObject = props;
    return PostCall(url,requestObject);
};
