import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import Progress from "../../common/Progress";
import Button from "react-bootstrap/Button";
import { Trans, useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import "./EnterYourEmail.scss";
import useGlobal from "../../../store";
import close from "../../../assets/close.svg";
import alert from "../../../assets/alert-warning@1x.png";
import SaveOrder from "../../../api/SaveOrder";
import SaveOrderDetails from "../../../api/SaveOrderDetails";
import { Spinner } from "../../common/spinner";
import { trackPromise } from "react-promise-tracker";

import {
  BindSaveOrder,
  BindDeathVerification,
  BindBirthVerification,
} from "../shippingInfo/ShippingInfoHelper";
import history from "../../common/history";
import { isMobileOnly } from "react-device-detect";

function EnterYourEmail(props) {
  const [showError, setShowError] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

  if (
    globalState.totalOrderRecords === undefined ||
    globalState.totalOrderRecords.length === 0
  ) {
    history.push(t("URLS.SELECT_VITAL_RECORD"));
  }

  const SignupSchema = yup.object().shape({
    email: yup
      .string()
      .required(t("SHIPPING_INFORMATION_ERROR_MSG.EMAIL_ERROR"))
      .email(t("SHIPPING_INFORMATION_ERROR_MSG.EMAIL_ERROR")),

    confEmail: yup
      .string()
      .required(t("SHIPPING_INFORMATION_ERROR_MSG.EMAIL"))
      .email(t("SHIPPING_INFORMATION_ERROR_MSG.EMAIL"))
      .when("email", {
        is: (email) => (email && email.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref("email")], t("SHIPPING_INFORMATION_ERROR_MSG.EMAIL")),
      }),
    attestionCheck: yup
      .boolean()
      .oneOf([true], t("SHIPPING_INFORMATION_ERROR_MSG.ATTESTATION")),
  });

  const { register, handleSubmit, control, errors, setValue, formState: {isDirty, isValid} } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(SignupSchema),
  });

  const integrateSaveOrder = (validateResponse, props) => {
    trackPromise(
      SaveOrder(BindSaveOrder(globalState, validateResponse, props)).then(
        (response) => {
          if (response.data.orderNumber !== "") {
            globalActions.addOrderNumber(response.data.orderNumber);
            integrateSaveOrderDetails(response.data);
          } else {
            setShowError(true);
          }
        }
      )
    ).catch(function (error) {
      console.log("SaveOrder error", error);
      history.push(t("URLS.ERROR_PAGE"));
    });
  };

  const integrateSaveOrderDetails = (saveOrderResponse) => {
    var saveOrderDetails = {};
    saveOrderDetails["applicationId"] = "OVRA";
    saveOrderDetails["agencyId"] = "DSHS";
    saveOrderDetails["uniqueIdentifier"] = globalState.uniqueIdentifier;
    saveOrderDetails["orderNumber"] = saveOrderResponse.orderNumber;
    if (globalState.totalOrderRecords.length > 0) {
      globalState.totalOrderRecords.forEach(function (item, index) {
        if (item.certificateInfoType === "deathVerification") {
          if (
            saveOrderDetails["deathVerifications"] === undefined ||
            saveOrderDetails["deathVerifications"] === "" ||
            saveOrderDetails["deathVerifications"].length === 0
          ) {
            saveOrderDetails["deathVerifications"] = [];
          }
          item.isOnlineOrder = true;
          saveOrderDetails["deathVerifications"].push(
            BindDeathVerification(item)
          );
        } else if (item.certificateInfoType === "birthVerification") {
          if (
            saveOrderDetails["birthVerifications"] === undefined ||
            saveOrderDetails["birthVerifications"] === "" ||
            saveOrderDetails["birthVerifications"].length === 0
          ) {
            saveOrderDetails["birthVerifications"] = [];
          }
          item.isOnlineOrder = true;
          saveOrderDetails["birthVerifications"].push(
            BindBirthVerification(item)
          );
        }
      });
    }
    trackPromise(
      SaveOrderDetails(saveOrderDetails)
        .then((response) => {
          if (response.status === 200) {
            props.nextCallback();
          } else {
            history.push(t("URLS.ERROR_PAGE"));
          }
        })
        .catch(function (error) {
          console.log("SaveOrderDetails error", error);
          history.push(t("URLS.ERROR_PAGE"));
        })
    );
  };
  const onSubmit = (data) => {
    setNextButtonDisabled(true);
    globalActions.shippingInformationData(data);

    integrateSaveOrder({shippingType: ""}, data);
  };

  return (
    <>
      <Container className="email-info-container">
        <Progress currentStep={3} totalSteps={4} />
        <form
          className="email-info-section"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <h1 className="email-info-heading">
              {t("ENTER_YOUR_EMAIL.HEADING")}
            </h1>
            <p className="para-5">
              {t("ENTER_YOUR_EMAIL.SUB_HEADING_1")}<br/>
              <Trans 
                i18nKey="ENTER_YOUR_EMAIL.SUB_HEADING_2"
                components={[
                  <b />
                ]}
              />
            </p>
          </div>
          <div className="confirmation-email">
            <div className="text-field-2">
              <label htmlFor="email" className="name">
                {t("SHIPPING_INFORMATION.EMAIL")}
              </label>
              <input
                className={`answer ${errors.email ? "error-red" : ""}`}
                type="text"
                name="email"
                id="email"
                ref={register}
                rules={{ required: true }}
                defaultValue={
                  globalState.shippingInformationData.email
                    ? globalState.shippingInformationData.email
                    : ""
                }
              />
              {errors.email && (
                <p className="error-red-content">{errors.email.message}</p>
              )}
            </div>
            <div className="text-field-2">
              <label htmlFor="confEmail" className="name">
                {t("SHIPPING_INFORMATION.CONF_EMAIL")}
              </label>
              <input
                className={`answer ${errors.confEmail ? "error-red" : ""}`}
                type="text"
                name="confEmail"
                id="confEmail"
                ref={register}
                rules={{ required: true }}
                defaultValue={
                  globalState.shippingInformationData.confEmail
                    ? globalState.shippingInformationData.confEmail
                    : ""
                }
              />
              {errors.confEmail && (
                <p className="error-red-content">{errors.confEmail.message}</p>
              )}
            </div>
          </div>

          <h1 className="attestation-heading">
            {t("SHIPPING_INFORMATION.ATTESTATION_HEADING")}
          </h1>
          <div className="form-checkbox-default">
            <input
              className={`checkbox ${errors.attestionCheck ? "error-red" : ""}`}
              type="checkbox"
              id="checkbox2"
              name="attestionCheck"
              ref={register}
              rules={{ required: true }}
            />
            <label htmlFor="checkbox2" className="checkbox-attestation">
              <Trans 
                i18nKey="ENTER_YOUR_EMAIL.CHECKBOX_ATTESTATION"
                components={[
                  <b />
                ]}
              />
            </label>
            {errors.attestionCheck && (
              <p className="error-red-content checkbox-error">
                {errors.attestionCheck.message}
              </p>
            )}
          </div>

          <div className="cc-form">
            {showError && (
              <Row className="email-Info-error-message">
                <Col
                  sm={2}
                  xs={2}
                  md={2}
                  lg={2}
                  className="email-Info-alert-icon"
                >
                  <img
                    className="shipping-Info-alert"
                    src={alert}
                    alt="alert"
                  />
                </Col>
                <Col
                  className="email-Info-error-content"
                  sm={8}
                  xs={8}
                  md={8}
                  lg={8}
                >
                  <p className="email-Info-error-text">
                    {t("SHIPPING_INFORMATION_ERROR_MSG.UNABLE_TO_PROCEED")}
                  </p>
                  <p className="email-Info-error-desc">
                    {t("SHIPPING_INFORMATION_ERROR_MSG.VERIFY_THE_INFO")}
                  </p>
                </Col>
                <Col
                  className="email-Info-close"
                  sm={2}
                  xs={2}
                  md={2}
                  lg={2}
                >
                  <img
                    src={close}
                    alt="close button"
                    onClick={() => setShowError(false)}
                  />
                </Col>
              </Row>
            )}
            <div>
              <Spinner />{" "}
            </div>
            <Row className="btn-group-align" sm={1} xs={1} md={2} lg={2}>
              <Col>
                <Button
                  variant="outline-primary"
                  className="back-button"
                  onClick={() => props.prevCallback()}
                >
                  {t("COMMONS.BACK")}
                </Button>
              </Col>
              <Col xs={{ order: isMobileOnly ? "first" : "last" }}>
                <Button
                  variant="primary"
                  className="next-button"
                  disabled={nextButtonDisabled || !isDirty || !isValid}
                  type="submit"
                >
                  {t("COMMONS.NEXT")}
                </Button>
              </Col>
            </Row>
          </div>
        </form>
      </Container>
    </>
  );
}

export default EnterYourEmail;
