import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import SendEmail from "../../../api/SendEmail";
import GetReceipt from "../../../api/GetReceipt";
import {BirthVerificationLetter, DeathVerificationLetter} from "../../../api/GetVerificationLetter";
import GetRecordSearchStatus from "../../../api/RecordSearch";
import useGlobal from "../../../store";
import i18n from "../../../i18n";
import "./OrderReceipt.scss";
import print from "../../../assets/printer.svg";
import history from "./../../common/history";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { Spinner } from "../../common/spinner";
import {formatDateToNumeric, formatDateToString} from "../../../lib/helpers/date"
import { TxAlert, TxAlertType } from 'texkit-ui';
import Download from "../../../assets/Download";

function OrderReceipt(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [receiptDetails, setReceiptDetails] = useState({});
  const [orderDetails, setOrderDetails] = useState([]);
  const [verificationLetterError, setVerificationLetterError] = useState(false);
  const [verificationLetterUrl, setVerificationLetterUrl] = useState(null);
  const { promiseInProgress } = usePromiseTracker();

  if (globalState.isOnlineOrder && globalState.totalOrderRecords !== undefined && globalState.totalOrderRecords.length > 0) {
    var recordSearch;
    var letterRequest;
    var downloadText;
    globalState.totalOrderRecords.forEach(function (item, index) {  
      if (item.certificateInfoType === "deathVerification") {
        downloadText = t("ORDER_RECEIPT.DOWNLOAD_DEATH_VERIFICATION_LETTER")
        recordSearch = {
          applicationId: "OVRA",
          agencyId: "DSHS",
          uniqueIdentifier: globalState.uniqueIdentifier,
          RecordType: "DEATH",
          EventDate: formatDateToNumeric(item.dateOfDeath),
          FirstName: item.firstName,
          LastName: item.lastName,
          Sex: item.selectedGender.value,
          City: item.city,
          County: item.countyOfDeath.value,
          DecedentDateOfBirth: formatDateToNumeric(item.dateOfBirth)
        };
        letterRequest = {
          applicationId: "OVRA",
          agencyId: "DSHS",
          uniqueIdentifier: globalState.uniqueIdentifier,
          name: [item.firstName, item.middleName, item.lastName].filter(Boolean).join(' '),
          deathDate: formatDateToNumeric(item.dateOfDeath),
          deathCity: item.city,
          deathCounty: item.countyOfDeath.value,
          birthDate: formatDateToNumeric(item.dateOfBirth),
          gender: item.selectedGender.value
        };
        if (item.parent2LastName !== undefined && item.parent2LastName !== null && item.parent2LastName.trim() !== "") {
          letterRequest["fatherName"] = [item.parent1FirstName, item.parent1MiddleName, item.parent1LastName].filter(Boolean).join(' ');
          letterRequest["motherName"] = [item.parent2FirstName, item.parent2MiddleName, item.parent2LastName].filter(Boolean).join(' ');
        } else {
          letterRequest["motherName"] = [item.parent1FirstName, item.parent1MiddleName, item.parent1LastName].filter(Boolean).join(' ');
        };
      } else if (item.certificateInfoType === "birthVerification") {
          downloadText = t("ORDER_RECEIPT.DOWNLOAD_BIRTH_VERIFICATION_LETTER")
          const birthDate = new Date(item.dateOfBirth); 
          recordSearch = {
            applicationId: "OVRA",
            agencyId: "DSHS",
            uniqueIdentifier: globalState.uniqueIdentifier,
            RecordType: "BIRTH",
            EventDate: item.dateOfBirth,
            FirstName: item.firstName,
            LastName: item.lastName,
            Sex: item.selectedGender.value,
            City: item.cityOfBirth,
            County: item.countyOfBirth.value
          };
          letterRequest = {
            applicationId: "OVRA",
            agencyId: "DSHS",
            orderNumber: globalState.orderId.toString(),
            uniqueIdentifier: globalState.uniqueIdentifier,
            name: [item.firstName, item.middleName, item.lastName].filter(Boolean).join(' '),
            birthDate: formatDateToNumeric(birthDate),
            birthCity: item.cityOfBirth,
            birthCounty: item.countyOfBirth.value,
            gender: item.selectedGender.value
          };
          if (item.parent2LastName !== undefined && item.parent2LastName !== null && item.parent2LastName.trim() !== "") {
            recordSearch["FathersFirstName"] = item.parent1FirstName;
            recordSearch["FathersLastName"] = item.parent1LastName;
            recordSearch["MothersFirstName"] = item.parent2FirstName;
            recordSearch["MothersLastName"] = item.parent2LastName;

            letterRequest["fatherName"] = [item.parent1FirstName, item.parent1MiddleName, item.parent1LastName].filter(Boolean).join(' ');
            letterRequest["motherName"] = [item.parent2FirstName, item.parent2MiddleName, item.parent2LastName].filter(Boolean).join(' ');
          } else {
            recordSearch["MothersFirstName"] = item.parent1FirstName;
            recordSearch["MothersLastName"] = item.parent1LastName;

            letterRequest["motherName"] = [item.parent1FirstName, item.parent1MiddleName, item.parent1LastName].filter(Boolean).join(' ');
          };
        }
    });
  }

  if (globalState.orderId !== undefined && globalState.orderId !== "") {
    var receiptData = {
      applicationId: "OVRA",
      agencyId: "DSHS",
      orderNumber: globalState.orderId.toString(),
      uniqueIdentifier: globalState.uniqueIdentifier,
    };

    var emailData = {
      applicationId: "OVRA",
      agencyId: "DSHS",
      orderNumber: globalState.orderId.toString(),
      uniqueIdentifier: globalState.uniqueIdentifier,
      onlineOrder:globalState.isOnlineOrder,
      language: i18n.language,
    };
  } else {
    history.push(t("URLS.SELECT_VITAL_RECORD"));
  }

  const handleVerificationLetterResponse = (verificationLetterResponse) => {
    const vlByteChars = atob(verificationLetterResponse.data.verificationLetter);
    const vlByteArray = Uint8Array.from(vlByteChars, c=>c.charCodeAt(0));
    const pdfBlob = new Blob([vlByteArray], {type: "application/pdf"});
    const vlUrl = URL.createObjectURL(pdfBlob);
    setVerificationLetterUrl(vlUrl);
    updateVerificationLetterStatus(true);
  };

  const handleTxEverResponse = (txEverResponse) => {
    if (txEverResponse.data === "Record Exists" || txEverResponse.data === "Multiple Records Found") {
      letterRequest["found"] = true;
      // Treat multiple matches as a match
      if (recordSearch.RecordType == "BIRTH") {
        trackPromise(BirthVerificationLetter(letterRequest)).then((verificationLetterResponse) => {
          handleVerificationLetterResponse(verificationLetterResponse);
        }).catch(function (error) {
          updateVerificationLetterStatus(false);
        });
      } else {
        trackPromise(DeathVerificationLetter(letterRequest)).then((verificationLetterResponse) => {
          handleVerificationLetterResponse(verificationLetterResponse);
        }).catch(function (error) {
          updateVerificationLetterStatus(false);
        });
      }
    } else if (txEverResponse.data === "Record Not Found") {
      letterRequest["found"] = false;
      if (recordSearch.RecordType == "BIRTH") {
        trackPromise(BirthVerificationLetter(letterRequest)).then((verificationLetterResponse) => {
          handleVerificationLetterResponse(verificationLetterResponse);
        }).catch(function (error) {
          updateVerificationLetterStatus(false);
        });
      } else {
        trackPromise(DeathVerificationLetter(letterRequest)).then((verificationLetterResponse) => {
          handleVerificationLetterResponse(verificationLetterResponse);
        }).catch(function (error) {
          updateVerificationLetterStatus(false);
        });
      }
    }
  };

  const updateVerificationLetterStatus = (verificationLetterSuccessful) => {
    if (globalState.isOnlineOrder) {
      if (verificationLetterSuccessful) {
        setVerificationLetterError(false);
        emailData["orderVerificationStatus"] = true;
      } else {
        setVerificationLetterError(true);
        emailData["orderVerificationStatus"] = false;
      }
      // send Email after we set the error status
      trackPromise(SendEmail(emailData)); 
    }
  };

  useEffect(() => {
    if (globalState.orderId !== undefined && globalState.orderId !== "") {
      if (globalState.isOnlineOrder) {
        trackPromise(GetRecordSearchStatus(recordSearch).then((response) => {
          handleTxEverResponse(response);
        })
        ).catch(function (error) {
          updateVerificationLetterStatus(false);
        });
      }
      const delayInMilliseconds = 500; //Forced delay of 1 sec to avoid error in order receipt
      setTimeout(function () {
        // Send email here only for mail orders - for online orders it is sent after we generate the letter or get an error
        if (!globalState.isOnlineOrder) {
          trackPromise(SendEmail(emailData)); 
        }
        trackPromise(
          GetReceipt(receiptData).then((response) => {
            {
              globalActions.receiptOrder(response.data);
              setReceiptDetails(response.data);

              if (Array.isArray(response.data.orderDetails) && response.data.orderDetails.length > 0) {
                if (!response.data.orderDetails || !Array.isArray(response.data.orderDetails) || response.data.orderDetails.length === 0) return;

                response.data.orderDetails.map((order) => {
                  if (order.recordType === "Fetal Death Certificate") order.recordType = "Fetal death certificate";
                  if (order.recordType === "StillBirth Certificate") order.recordType = "Certificate of birth resulting in stillbirth"
                })
              };

              setOrderDetails(response.data.orderDetails);
            }
          })
        ).catch(function (error) {
          console.log("ReceiptDetails error", error);
          history.push(t("URLS.ERROR_PAGE"));
        });
      }, delayInMilliseconds);
    } else {
      history.push(t("URLS.SELECT_VITAL_RECORD"));
    }
  }, []);
  return (
    <>
      <Container id="printHTML" className="order-receipt-container">
        <hr />
        <h1 className="order-receipt-heading">{t("ORDER_RECEIPT.HEADING")}</h1>
        {globalState.isOnlineOrder && !promiseInProgress && (verificationLetterError ?
          (<TxAlert
            type={TxAlertType.Error}
            heading={t("ORDER_RECEIPT.VERIFICATION_LETTER_ERROR")}
            children={
              <div>
                <p>
                  {t("ORDER_RECEIPT.VERIFICATION_LETTER_ERROR_1")}
                  <a 
                    href={t("ERROR.TECH_SUPPORT_LINK")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("ERROR.TECH_SUPPORT_NAME")}
                  </a>
                  {t("ORDER_RECEIPT.VERIFICATION_LETTER_ERROR_2")}
                </p>
              </div>
            }
          />) :
          (
            <>
              <p className="order-receipt-desc">{t("ORDER_RECEIPT.DESCRIPTION_ONLINE_0")}</p>
              <p className="order-receipt-desc"><b>{t("ORDER_RECEIPT.DESCRIPTION_ONLINE_1")}</b>{t("ORDER_RECEIPT.DESCRIPTION_ONLINE_2")}</p>
              <p className="order-receipt-download">
                <span className="order-receipt-downloadicon"><Download width={24} height={24} color="blue"/></span>
                <span
                  onClick={()=> {
                    const width = window.innerWidth;
                    const height = window.innerHeight;

                    window.open(
                      verificationLetterUrl, 
                      '_blank',
                      `width=${width},height=${height},top=0,left=0,resizable=yes,scrollbars=yes`
                    );
                  }}
                >
                  <span className="order-receipt-letter">{downloadText}</span>
                </span>
              </p>
            </>
          )
        )}
        {!globalState.isOnlineOrder && <p className="order-receipt-desc">{t("ORDER_RECEIPT.DESCRIPTION")}</p>}
        <label className="order-receipt">{t("ORDER_RECEIPT.RECEIPT")}</label>
        <Spinner />
        <form className="order-review-content-section">
          <div className="itemized-total-section">
            <Row className="payment-order-sub-total">
              <Col className="payment-font-style">
                <label>{t("ORDER_RECEIPT.RECORDTOTAL")}</label>
              </Col>
              <Col className="payment-sub-amount">
                <p>
                  $
                  {(
                    parseFloat(receiptDetails.subTotal) +
                    parseFloat(receiptDetails.serviceFee)
                  ).toFixed(2)}
                </p>
              </Col>
            </Row>

            <Row className="payment-order-sub-total-2">
              <Col className="payment-font-style">
                <label>{t("ORDER_RECEIPT.DONATIONS")}</label>
              </Col>
              <Col className="payment-sub-amount">
                {" "}
                <p>${receiptDetails.donationAmount}</p>{" "}
              </Col>
            </Row>

            <Row className="payment-order-sub-total">
              {/* STRY0172062 - added ShippingType localization  */}
              <Col className="payment-font-style">
                <label>
                  {t("ORDER_RECEIPT.SHIPPING_FEE")}{" "}
                    {(!globalState.isOnlineOrder && receiptDetails.shippingType) ? (
                        <span>
                          (
                          {t(
                            "COMMONS." +
                              receiptDetails.shippingType
                                .toUpperCase()
                                .replace(" ", "_")
                          )}
                          )
                        </span>
                    ) : (
                      ""
                    )}
                </label>
              </Col>
              <Col className="payment-sub-amount">
                {" "}
                <p>${receiptDetails.shippingFee}</p>{" "}
              </Col>
            </Row>

            <Row className="payment-order-sub-total-2">
              <Col>
                <label className="payment-font-style-total">
                  {t("ORDER_RECEIPT.TOTAL")}
                </label>
              </Col>
              <Col className="payment-sub-amount total-color">
                {" "}
                <p>${receiptDetails.total}</p>{" "}
              </Col>
            </Row>
          </div>
          <div>
            {" "}
            <Row
              className="order-information-container"
              sm={1}
              xs={1}
              md={2}
              lg={2}
            >
              <Col>
                <label className="info-titles">
                  {t("ORDER_RECEIPT.ORDER_NUMBER")}
                </label>
                <p className="order-field-detail">
                  {receiptDetails.remittanceNumber}
                </p>

                <label className="info-titles">
                  {t("ORDER_RECEIPT.PAYMENT_METHOD")}
                </label>
                <p className="order-field-detail">
                  {receiptDetails.paymentMethod}
                </p>

                <label className="info-titles">
                  {t("ORDER_RECEIPT.TRANSACTION_NUMBER")}
                </label>
                <p className="order-field-detail">
                  {receiptDetails.transactionNumber}
                </p>
              </Col>
              <Col>
                <label className="info-titles">
                  {t("ORDER_RECEIPT.TRANSACTION_DATE")}
                </label>
                <p className="order-field-detail">
                  {receiptDetails.transactionDate}
                </p>

                <label className="info-titles">
                  {t("ORDER_RECEIPT.PAYMENT_STATUS")}
                </label>
                <p className="order-field-detail-paid">
                  {receiptDetails.paymentStatus}
                </p>
              </Col>
            </Row>
            <div className="record-information">
              <label className="order-receipt">
                {t("ORDER_RECEIPT.RECORD_ORDER_INFORMATION")}
              </label>

              <div className="record-detail-information">
                <div className="order-information-container">
                  {receiptDetails &&
                    orderDetails.map((orderDetailArray, index) => {
                      return (
                        <div>
                          {index !== 0 && <div className="rectangle"></div>}
                          <Row sm={1} xs={1} md={2} lg={2}>
                            <Col>
                              <label className="info-titles">
                                {t("ORDER_RECEIPT.NAME_ON_RECORD")}
                              </label>
                              <p className="order-field-detail">
                                {orderDetailArray.firstName +
                                  " " +
                                  orderDetailArray.middleName +
                                  " " +
                                  orderDetailArray.lastName}
                                <br />
                                {orderDetailArray.spouse2FirstName
                                  ? orderDetailArray.spouse2FirstName
                                  : ""}{" "}
                                {orderDetailArray.spouse2MiddleName
                                  ? orderDetailArray.spouse2MiddleName
                                  : ""}{" "}
                                {orderDetailArray.spouse2LastName
                                  ? orderDetailArray.spouse2LastName
                                  : ""}
                              </p>
                              <label className="info-titles">
                                {t("ORDER_RECEIPT.DATE_RANGE_RECORD")}
                              </label>
                              <p className="order-field-detail">
                                {orderDetailArray.date}
                              </p>
                              <label className="info-titles">
                                {t("ORDER_RECEIPT.AMOUNT")}
                              </label>
                              <p className="order-field-detail">
                                $
                                {parseFloat(orderDetailArray.amount).toFixed(2)}
                              </p>
                            </Col>
                            <Col>
                              <label className="info-titles">
                                {t("ORDER_RECEIPT.RECORD_TYPE")}
                              </label>
                              <p className="order-field-detail">
                                {orderDetailArray.recordType}{" "}
                                {orderDetailArray.recordFormat
                                  ? orderDetailArray.recordFormat
                                  : ""}
                              </p>
                              <label className="info-titles">
                                {t("ORDER_RECEIPT.NUMBER_OF_COPIES")}
                              </label>
                              <p className="order-field-detail">
                                {orderDetailArray.numberOfCopies}
                                {globalState.isOnlineOrder && t("ORDER_REVIEW.ONLINE")}
                                </p>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </form>
        <div>
          <Button className="alternate-way-button" onClick={window.print}>
            <img className="printlink" src={print} alt="" />
            <p className="printlink">{t("ORDER_RECEIPT.PRINT")}</p>
          </Button>
        </div>
      </Container>
      <div className="feed-back-bg ">
        <Container className="order-feedback-container">
          <p className="order-feedback-text">
            <span className="feedback-bold">{t("ORDER_RECEIPT.FEEDBACK")}</span>
            {t("ORDER_RECEIPT.FEEDBACK_TEXT")}
          </p>

          <Row className="btn-birth" sm={1} xs={1} md={2} lg={2}>
            <Col>
              <a
                className="feedback-button button-primary"
                href={t("ORDER_RECEIPT.FEEDBACK_LINK")}
                target="blank"
              >
                {t("ORDER_RECEIPT.FEEDBACK_BUTTON")}
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default OrderReceipt;
