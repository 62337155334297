import PostCall from "./CommonApi";


const GetRecordSearchStatus = (request) => {
  let url =  "/v1/ovra/rest/txever/GetRecordSearchStatus";
  let requestObject = request;
  return PostCall(url, requestObject);
}

export default GetRecordSearchStatus;
