import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./../../styles/Footer.scss";
import logo from "../../assets/tx_logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  return (
    <footer className="footer">
      <Container>
        <Row sm={1} xs={1} md={1} lg={3}>
          {/* > */}
          <Col sm={12} xs={12} md={6} lg={6} className="footer-item">
            <a target="blank" href={t("CONTACT_MODAL_MESSAGE.TEXAS_GOV_LINK")}>
              <img className="tx-footer-logo" src={logo} alt="" />
            </a>

            <p className="footer-title">State of Texas. All rights reserved.</p>
          </Col>

          <Col
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className="footer-link-section  footer-item"
          >
            <a href="https://www.texas.gov/#policies" className="footer-link">
              {t("CONTACT_MODAL_MESSAGE.POLICIES")}
            </a>

            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title className="contact-modal-heading-1">
                  {" "}
                  {t("CONTACT_MODAL_MESSAGE.CONTACT_INFORMATION")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="contact-modal-container">
                <p className="contact-modal-text">
                  <a
                    className="contact-modal"
                    target="blank"
                    href="https://www.texas.gov/help-support/"
                  >
                    {t("CONTACT_MODAL_MESSAGE.TECHNICAL_ASSISTANCE")}
                  </a>
                </p>

                <p className="contact-modal-text">
                  {t("CONTACT_MODAL_MESSAGE.ORDER_STATUS")}&nbsp;
                  <span>
                    <a
                      className="contact-modal"
                      target="blank"
                      href={t("CONTACT_MODAL_MESSAGE.ORDER_STATUS_LINK")}
                    >
                      {t("CONTACT_MODAL_MESSAGE.PLEASE_USE_OUR")}
                    </a>
                  </span>
                  <span className="contact-modal-text contact-modal-order-number">
                    &nbsp;{t("CONTACT_MODAL_MESSAGE.ORDER_NUMBER_DATE")}
                  </span>
                </p>

                <p className="contact-modal-text">
                  {t("CONTACT_MODAL_MESSAGE.OTHER_QUESTIONS")}&nbsp;
                  <span>
                    <a
                      className="contact-modal"
                      target="blank"
                      href="mailto: vrstatus@dshs.texas.gov"
                    >
                      {t("CONTACT_MODAL_MESSAGE.SEND")}
                    </a>
                  </span>
                </p>

                <p className="contact-modal-text">
                  {t("CONTACT_MODAL_MESSAGE.PROCESSING_TIMES")}&nbsp;
                  <span>
                    <a
                      className="contact-modal"
                      target="blank"
                      href="https://dshs.texas.gov/vs/faq/"
                    >
                      {t("CONTACT_MODAL_MESSAGE.TEXAS_VITAL")}
                    </a>
                  </span>
                </p>
              </Modal.Body>
              <div className="modal-close-button">
                <button
                  className="primary-blue-button modal-contact-button"
                  onClick={() => setShow(false)}
                >
                  {t("CONTACT_MODAL_MESSAGE.OK_GOT_IT_BUTTON")}
                </button>
              </div>
            </Modal>

            <Link onClick={() => setShow(true)}>
              {" "}
              <span className="footer-link menu__MenuTrigger">
                {" "}
                {t("CONTACT_MODAL_MESSAGE.CONTACT")}{" "}
              </span>{" "}
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
