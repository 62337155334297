import React, { useState, useEffect, useRef } from "react";
import Iframe from "react-iframe";
import { Container, Row, Col } from "react-bootstrap";
import history from "./../../../../components/common/history";
import useEventListener from "./../../UseEventListener";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import "./Payment.scss";
import Progress from "../../Progress";
import useGlobal from "../../../../store";
import GetItemizedTotal from "../../../../api/GetItemizedTotal";
import GetAmountDistribution from "../../../../api/AmountDistribution";
import GetCheckout from "../../../../api/GetCheckout";
import GetSubmitPayment from "../../../../api/GetSubmitPayment";
import GetPublicIPAddress from "../../../../api/GetPublicIPAddress";
import { Error, ErrorInPage } from "./../../../common/index";
import { trackPromise } from "react-promise-tracker";
import { Spinner } from "../../../common/spinner";
import Loader from "react-loader-spinner";
import { evnetGA } from "./../../../common/GoogleAnalytics";
function Payment(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [paymentUrl, setPaymenturl] = useState("");
  const [paymentToken, setPaymentToken] = useState("");
  const [showError, setShowError] = useState(false);
  const [showInpageError, setShowInpageError] = useState(false);
  const [errorReference, setErrorReference] = useState("");
  const [paymentError, setPaymentError] = useState(false);
  const [paymentCallInProgress, setPaymentCallInProgress] = useState(false);
  const [iFrameLoading, setIFrameLoading] = useState(false);
  const applicationId = "OVRA";
  const agencyId = "DSHS";
  const uniqueIdentifier = globalState.uniqueIdentifier;
  const [orderHasFetalDeathCert, setOrderHasFetalDeathCert] = useState(false);

  const checkoutObject = (ip) => {
    const checkOutDetailsRequestObject = {
      applicationId,
      agencyId,
      uniqueIdentifier,
      inTransactionId: "0",
      totalAmount: JSON.parse(sessionStorage.getItem("paymentItem"))
        .totalAmount,
      orderNumber: globalState.orderId,
      transactionStatus: "NOT PAID",
      checkoutConfiguration: {
        host: ip,
        viewOptions: {
          cvvRequired: true,
          achRequired: false,
          addressRequired: true,
          allowedCardTypes: [],
        },
      },
    };
    return checkOutDetailsRequestObject;
  };
  const hideError = () => {
    setShowInpageError(false);
  };
  const hidePaymentError = () => {
    setPaymentError(false);
  };
  setTimeout(() => {
    setIFrameLoading(false);
  }, 2000);

  const publicIPAddressRef = useRef();
  useEffect(() => {
    GetPublicIPAddress(
      uniqueIdentifier,
      applicationId,
      agencyId,
      paymentToken
    ).then((resp) => {
      publicIPAddressRef.current = resp;
    });
  }, [uniqueIdentifier, paymentToken]);

  // ORDER FEE CALCULATION
  const GetItemizedTotalMethod = () => {
    let orderId = globalState.orderId;
    sessionStorage.setItem("orderId", orderId);
    trackPromise(
      GetItemizedTotal(orderId, globalState.uniqueIdentifier).then(
        (response) => {
          globalActions.addItemizedTotalPayment(response.data);
          sessionStorage.setItem("paymentItem", JSON.stringify(response.data));
          // AMOUNT DISTRIBUTION CALL
          trackPromise(
            GetAmountDistribution(
              sessionStorage.getItem("orderId"),
              globalState.uniqueIdentifier
            ).then((response) => {
              globalActions.addAmountDistribution(response.data);
              // CHECKOUT CALL
              if (publicIPAddressRef.current !== "") {
                let checkoutRequest = checkoutObject(
                  publicIPAddressRef.current
                );
                GetCheckout(checkoutRequest)
                  .then((response) => {
                    globalActions.addCheckOutOrder(response.data);
                    setIFrameLoading(true);
                    setPaymenturl(response.data.url);
                    setPaymentToken(response.data.paymentToken);
                  })
                  // CHECKOUT ERROR CATCH
                  .catch(function (error) {
                    setErrorReference(JSON.stringify(error));
                    setShowError(true);
                  });
              } else {
                setShowError(true);
              }
            })
          )
            // AMOUNT DISTRIBUTION ERROR CATCH
            .catch(function (error) {
              setErrorReference(JSON.stringify(error));
              setShowError(true);
            });
        }
      )
    )
      // ORDER ERROR CATCH
      .catch(function (error) {
        setErrorReference(JSON.stringify(error));
        setShowError(true);
      });
  };
  useEffect(() => {
    if (globalState.orderId !== undefined && globalState.orderId !== "") {
      GetItemizedTotalMethod();
    } else {
      history.push(t("URLS.SELECT_VITAL_RECORD"));
    }
  }, []);
  const receiveFrameMessage = (event) => {
    // Notes: Action Statuses from PaymentFrame
    // public enum PaymentAccountActionStatus
    // {
    //   AddMode, – payment method input form displayed
    //   EditMode, – payment method edit form displayed
    //   PaymentAccountAdded, – payment method successfully added
    //   PaymentAccountCached, – payment method added to cache
    //   UpdateComplete, – payment method successfully updated
    //   Cancel, – user has canceled the add/edit operation
    //   Timeout, – the iFrame has timed out
    //   Error – an error has been returned from the iFrame host server
    //   }
    if (!event || typeof event.data !== "string") return;
    const status = event.data.toLowerCase();
    if (!status) return;
    if (status === "error" || status === "timeout") {
      if (typeof onError === "function") onError(status);
    }
    if (typeof onCancel === "function" && status === "cancel") onCancel(status);
    if (
      typeof onSuccess === "function" &&
      (status === "updatecomplete" ||
        status === "paymentaccountadded" ||
        status === "paymentaccountcached")
    )
      onSuccess(status);
  };
  useEventListener("message", receiveFrameMessage);
  const onSuccess = (status) => {
    if (paymentCallInProgress === false) {
      setPaymentCallInProgress(true);
      setPaymentError(false);
      window.scrollTo(0, 0);
      trackPromise(
        GetSubmitPayment(
          paymentToken,
          globalState.amountDistribution.usasCodes,
          globalState.checkOutOrder.outTransactionId,
          globalState.checkOutOrder.traceNumber,
          globalState.uniqueIdentifier
        )
      )
        .then((response) => {
          setPaymentCallInProgress(false);
          //Google Analytics- Send Payment Event
          evnetGA(
            "OVRA",
            "Payment",
            "orderID: " +
              globalState.orderId +
              " traceNumber: " +
              globalState.checkOutOrder.traceNumber,
            1,
            true
          );
          props.nextCallback
            ? props.nextCallback()
            : history.push("./order-receipt");
        })
        .catch(function (error) {
          if (error.message.includes("500")) {
            setShowError(true);
            return;
          } else {
            setPaymentCallInProgress(false);
            setPaymentError(true);
          }

          window.scrollTo(0, document.body.scrollHeight);
          // PAYMENT RESUBMISSION IFRAME ON FAILURE
          if (publicIPAddressRef.current !== "") {
            let checkoutRequest = checkoutObject(publicIPAddressRef.current);
            trackPromise(
              GetCheckout(checkoutRequest).then((response) => {
                globalActions.addCheckOutOrder(response.data);
                setPaymenturl(response.data.url);
                setPaymentToken(response.data.paymentToken);
              })
            ).catch(function (error) {
              setErrorReference(JSON.stringify(error));
              setShowError(true);
            });
          } else {
            setShowError(true);
          }
        });
    }
  };
  const onCancel = (status) => {};
  const onError = (status) => {
    history.push(t("URLS.ERROR_PAGE"));
    setShowInpageError(true);
  };
  useEffect(() => {
    if (globalState.totalOrderRecords) {
      globalState.totalOrderRecords.map((cert) => {
        if (
          (cert.certificateInfoType &&
            cert.certificateInfoType === "stillbirthDeath") ||
          cert.certificateInfoType === "fetalDeath"
        ) {
          setOrderHasFetalDeathCert(true);
          return;
        }
      });
    }
  }, []);
  return (
    <>
      {!showError && (
        <Container className="payment-review-container">
          <Progress currentStep={4} totalSteps={4} />
          <Spinner />
          <h1 className="payment-review-heading">{t("PAYMENT.HEADING")}</h1>
          <label className="payment-desc">
            {t("PAYMENT.DESC")}
            <span className="payment-warning">
              {t("PAYMENT.DESC_CONTINUATION")}
            </span>{" "}
            {t("PAYMENT.DESC_CONTINUATION_2")}
            <br />
            <br />
            {orderHasFetalDeathCert && (
              <>
                <span className="payment-warning">{t("PAYMENT.DESC")}</span>
                {t("PAYMENT.DESC_CONTINUATION_3")}
              </>
            )}
          </label>
          <h2 className="payment-sub-heading">{t("PAYMENT.ORDER_FEE")}</h2>
          <div className="itemized-total-section">
            <Row className="payment-order-sub-total">
              <Col className="payment-font-style">
                <label>{t("PAYMENT.RECORD_SUB_TOTAL")}</label>
              </Col>
              <Col className="payment-sub-amount">
                {" "}
                {globalState.paymentItemizedTotal.certificateFee && (
                  <p>
                    $
                    {(
                      parseFloat(
                        globalState.paymentItemizedTotal.certificateFee
                      ) +
                      parseFloat(
                        globalState.paymentItemizedTotal.convenienceFee
                      )
                    ).toFixed(2)}
                  </p>
                )}{" "}
              </Col>
            </Row>
            <Row className="payment-order-sub-total-2">
              <Col className="payment-font-style">
                <label>{t("PAYMENT.DONATIONS")}</label>
              </Col>
              <Col className="payment-sub-amount">
                {" "}
                <p>${globalState.paymentItemizedTotal.donationAmount}</p>{" "}
              </Col>
            </Row>
            <Row className="payment-order-sub-total">
              {/* STRY0172062 - Display Shipping Type on Payment page along with Shipping Fee*/}
              <Col className="payment-font-style">
                <label>
                  {t("PAYMENT.SHIPPING_FEE")}{" "}
                  {(!globalState.isOnlineOrder && globalState.paymentItemizedTotal.shippingType) ? (
                    <span>
                      (
                      {t(
                        "COMMONS." +
                          globalState.paymentItemizedTotal.shippingType
                            .toUpperCase()
                            .replace(" ", "_")
                      )}
                      )
                    </span>
                    ) : (
                    ""
                    )}
                </label>
              </Col>
              <Col className="payment-sub-amount">
                {" "}
                <p>${globalState.paymentItemizedTotal.shippingAmount}</p>{" "}
              </Col>
            </Row>
            <Row className="payment-order-sub-total-2">
              <Col>
                <label className="payment-font-style-total">
                  {t("PAYMENT.TOTAL")}
                </label>
              </Col>
              <Col className="payment-sub-amount total-color">
                {" "}
                <p>${globalState.paymentItemizedTotal.totalAmount}</p>{" "}
              </Col>
            </Row>
          </div>
          {iFrameLoading && (
            <div className="spinner">
              <Loader
                type="ThreeDots"
                color="#007bff"
                height="100"
                width="100"
              />
            </div>
          )}
          {/* IFRAME */}
          <div
            className={
              paymentCallInProgress
                ? "iframe-align iframe-hide"
                : "iframe-align "
            }
          >
            {paymentUrl !== "" && (
              <Iframe
                className="iframe-align"
                url={paymentUrl}
                width="100%"
                height="1400px"
              />
            )}
          </div>
          {paymentError && (
            <ErrorInPage
              title={t("PAYMENT.PAYMENT_ERROR_TITLE")}
              description={t("PAYMENT.PAYMENT_ERROR_DESC")}
              hideCallBack={hidePaymentError}
            />
          )}
          {showInpageError && (
            <ErrorInPage
              title={t("PAYMENT.ERROR_TITLE")}
              description={t("PAYMENT.ERROR_DESCRIPTION")}
              hideCallBack={hideError}
            />
          )}
        </Container>
      )}
      <div className="bottom-height-adjust"></div>
      {showError && <Error errorReference={globalState.uniqueIdentifier} />}
    </>
  );
}
export default Payment;
